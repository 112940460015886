import { Route, Routes, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../node_modules/bootstrap4-toggle/css/bootstrap4-toggle.min.css";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import Upload from "./components/Upload";
import { IoArrowBack } from "react-icons/io5";
import Navbar from "./components/Navbar";
import Add_items from "./components/Add_items";
import ConfirmationTable from "./components/ConfirmationTable";
import DataForDate from "./components/DataForDate";
import Login from "./components/Login";
import AfterLogin from "./components/AfterLogin";
import Dashboard from "./components/Dashboard";
import DataForDateDash from "./components/DataForDateDash";
import Costumer from "./components/Customer";
import NavbarWrapper from "./components/NavbarWrapper";
import Prediction from "./components/Prediction";

function App() {


  return (
    <BrowserRouter>
     <NavbarWrapper/>
      <div>
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dash" element={<Dashboard />} />
      <Route path="/afterlogin" element={<AfterLogin />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/confirm" element={<ConfirmationTable />} />
      <Route path="/table" element={<DataForDate />} />
      <Route path="/table-dash" element={<DataForDateDash />} />
      <Route path="/add" element={<Add_items />} />
      <Route path="/customer" element={<Costumer/>} />
      <Route path="/Prediction" element={<Prediction/>} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
   
</Routes>

      </div>
    
    </BrowserRouter>
  );
}

export default App;
