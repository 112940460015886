import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RiseLoader } from "react-spinners";

const DataForDate = () => {
    const [responseData, setResponseData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState('');
    const [dates, setDates] = useState([]);
    const [totals, setTotals] = useState({});
    const [loading, setLoading] = useState(false);

    const addDate = async (e) => {
        e.preventDefault();
        
        const userId = localStorage.getItem("key");
        const adjustedStartDate = new Date(startDate);
        adjustedStartDate.setDate(adjustedStartDate.getDate());
      
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setDate(adjustedEndDate.getDate());
      
        // console.log("start Date - ", adjustedStartDate)
        // console.log("end Date - ",adjustedEndDate);

        setLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}calculation/dateRange`,
                {
                    params: {
                        startDate: adjustedStartDate,
                        endDate: adjustedEndDate,
                        userId:userId
                    }
                }
            );
            setResponseData(response.data);
            const uniqueDates = [
                ...new Set(response.data.map((item) => item.date.split("T")[0])),
            ].sort(); // Sort dates in ascending order
            setDates(uniqueDates);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const calculateTotals = () => {
            const totalsObj = {};
            const countsObj = {};

            responseData.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key !== 'date' && key !== '_id') {
                        if (key === 'average') {
                            countsObj[key] = (countsObj[key] || 0) + 1;
                            totalsObj[key] = (totalsObj[key] || 0) + parseFloat(item[key]);
                        } else {
                            totalsObj[key] = (totalsObj[key] || 0) + parseFloat(item[key]);
                        }
                    }
                });
            });

            Object.keys(countsObj).forEach(key => {
                totalsObj[key] = (totalsObj[key] / countsObj[key]).toFixed(2);
            });

            setTotals(totalsObj);
        };

        calculateTotals();
    }, [responseData]);

    return (
        <>
            <form style={{margin:"1rem"}}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Start Date</label>
                    <input type="date" className="form-control" id="exampleInputEmail1" value={startDate} onChange={(e) => { setStartDate(e.target.value); setEndDate(e.target.value) }} />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">End Date</label>
                    <input type="date" className="form-control" id="exampleInputPassword1" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <input type="radio" className="btn-check" name="options" id="option4" onClick={() => { setStartDate(''); setEndDate(""); }} autoComplete="off" />
                <label className="btn btn-secondary" htmlFor="option4">Clear Date</label>
                <button type="submit" className="btn-get-started-main" onClick={addDate}>Search</button>
            </form>
            {loading ? (
                <div style={{ marginTop: "3rem", display: 'flex', justifyContent: "center" }}>
                    <RiseLoader size={40} color='black' />
                </div>
            ) : (
                <div style={{ overflowX: 'auto',margin:"1rem" }}>
                    <h2>Data Display Table</h2>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Dates</th>
                                {responseData.length > 0 && Object.keys(responseData[0]).filter(key => key !== 'date' && key !== '_id' && key !== 'userId').map(key => (
                                    <th key={key}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dates.map(date => (
                                <tr key={date}>
                                    <td>{date}</td>
                                    {responseData.length > 0 && Object.keys(responseData[0]).filter(key => key !== 'date' && key !== '_id'&& key !== 'userId').map(key => {
                                        const item = responseData.find(item => item.date.split('T')[0] === date);
                                        return <td key={key}>{item ? item[key] : ''}</td>;
                                    })}
                                    <td></td> {/* Placeholder for total */}
                                </tr>
                            ))}
                            <tr>
                                <td>Total</td>
                                {responseData.length > 0 && Object.keys(responseData[0]).filter(key => key !== 'date' && key !== '_id'&& key !== 'userId').map(key => (
                                    <td key={key}>{totals[key]}</td>
                                ))}
                                <td></td> {/* Placeholder for total */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default DataForDate;

