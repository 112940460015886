import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import imgs2 from '../logo/maa ki chai logo.gif';
import axios from 'axios';
import { ToastContainer,toast} from 'react-toastify';


const Customer = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.length === 10;
  };
  const notify = () => toast.error('🦄 Phone number already exists!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
 
    });;

  const sanitizePhoneNumber = (phoneNumber) => {
    // Remove all non-numeric characters
    let sanitized = phoneNumber.replace(/\D/g, '');
    
    // Take the last 10 digits
    return sanitized.slice(-10);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setError('');
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const sanitizedNumber = sanitizePhoneNumber(value);
    
    if (!validatePhoneNumber(sanitizedNumber)) {
      setError('Please enter a valid 10-digit phone number');
      return;
    }
console.log(sanitizedNumber)
    try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}checkAndSavePhoneNumber?number=${sanitizedNumber}`);
      console.log('Response:', response.data);
      if(response.data == "Phone number already exists"){
        
   notify();
        return ;
      }

       window.open('https://g.page/r/CTOJNoQjs61xEAE/review', '_blank');

      // Handle successful response here (e.g., show success message, clear form)
      setValue('');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while submitting the phone number');
    }
  };
//   const submitHandler = async (e) => {
//     e.preventDefault();
    
//     const sanitizedNumber = sanitizePhoneNumber(value);
    
//     if (!validatePhoneNumber(sanitizedNumber)) {
//       setError('Please enter a valid 10-digit phone number');
//       return;
//     }
// console.log(sanitizedNumber)
//     try {
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}checkAndSavePhoneNumber?number=${sanitizedNumber}`);
//       console.log('Response:', response.data);
//       if(response.data == "Phone number already exists"){
        
//    notify();
//         return ;
//       }

//        window.open('https://g.page/r/CTOJNoQjs61xEAE/review', '_blank');

//       // Handle successful response here (e.g., show success message, clear form)
//       setValue('');
//     } catch (error) {
//       console.error('Error:', error);
//       setError('An error occurred while submitting the phone number');
//     }
//   };

  return (
    <>
   
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"70vh",zIndex:"100"}}>
      <div style={{textAlign:"center", width:"100%"}}>
      <ToastContainer />

        <div>
          <img style={{width:"12rem"}} src={imgs2} alt='ma ki chai logo'/>
        </div>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Please Enter Your Phone Number</Form.Label><br />
            <Form.Control 
              style={{width:"80%", padding:'10px', borderRadius:"20px", margin: "0 auto"}}
              type="tel"
              placeholder="Enter phone number"
              value={value}
              onChange={handleChange}
            />
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
          </Form.Group>
          <div className="mb-2">
            <Button variant="warning" type="submit" size="lg">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
    </>
  );
};

export default Customer;