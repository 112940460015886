import React from 'react'

const Prediction = () => {
  return (
    <div>
      Prediction
    </div>
  )
}

export default Prediction
