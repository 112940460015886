import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/config";
import imgs from '../logo/tracklogo2.png';

const Navbar = () => {
  const navigate = useNavigate();

  const [location,setLocation] = useState("");
  
  const key = localStorage.getItem("key");
 

  useEffect(()=>{
    const username = localStorage.getItem("username")
 setLocation(username);
  })

  const handleLogout = () => {
    localStorage.removeItem("key");
    navigate("/");
    // signOut(auth)
    //   .then(() => {
    //     // Clear user data from localStorage
    //     localStorage.removeItem("encryptedEmail");
    //     // Redirect to home or login page
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //     console.error("Error signing out: ", error);
    //   });
  };

  
  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: "purple" }}
    >
      
      <div className="container-fluid">
   
    

          <NavLink to="/" style={{ textDecoration: "none", color: "#d8cbc7" }} className="navbar-brand">
            <img className="logo" style={{ width: "11em" }} src={imgs} alt="logo" />
          </NavLink>
       

          <p style={{color:"#F08080",margin:"0px 50px 0px 50px"}}>{location}</p>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
{key && <div className="navbar-nav">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/afterlogin"
              className="nav-item nav-link active"
            >
              Dashboard
            </Link>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/table"
              className="nav-item nav-link active"
            >
              Search By Date
            </Link>
            {/* <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/add"
              className="nav-item nav-link active"
            >
              Add Items
            </Link> */}
            {/* <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/download"
              className="nav-item nav-link active"
            >
              Download
            </Link> */}
      
          </div>}
          <div className="navbar-nav ms-auto">
            {localStorage.getItem("key") && (
              <button
                style={{ color: "white" }}
                onClick={handleLogout}
                className="nav-item nav-link"
              >
                Logout
              </button>
            )}
            
          </div>
         

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
