import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";

const NavbarWrapper = () => {
  const location = useLocation();

  // Conditionally render Navbar based on the current pathname
  if (location.pathname === "/customer") {
    return null;
  }

  return <Navbar />;
};

export default NavbarWrapper;
