import React from "react";
import "./afterlogin.css";
import { useNavigate } from "react-router-dom";
const AfterLogin = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="af_main_af">
        <div
          className="card"
          style={{
            width: "18rem",
            height: "auto",
            marginTop: "1.5rem",
            backgroundColor: "#240a2b",
            color: "white",
          }}
        >

        </div>

        <div
          className="card"
          style={{
            width: "18rem",
            marginTop: "1.5rem",
            backgroundColor: "#240a2b",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-title">Dashboard</h4>
            <p className="card-text">
              Click Here To see the sales report weekly,monthly or yearly
            </p>
            <a
              href="#"
              className="btn btn-primary"
              onClick={() => {
                localStorage.getItem("key")
                  ? navigate("/dash")
                  : alert("Please select the location..");
              }}
            >
              Go
            </a>
          </div>
        </div>
        <div
          className="card"
          style={{
            width: "18rem",
            marginTop: "1.5rem",
            backgroundColor: "#240a2b",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-title">Upload Image</h4>
            <p className="card-text">
              Click Here To Upload Image And Update Your Todays Expense{" "}
            </p>
            <a
              href="#"
              className="btn btn-primary"
              onClick={() => {
                localStorage.getItem("key")
                  ? navigate("/upload")
                  : alert("Please select the location..");
              }}
            >
              Upload
            </a>
          </div>
        </div>
        {/* <div
          className="card"
          style={{
            width: "18rem",
            marginTop: "1.5rem",
            backgroundColor: "#240a2b",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-title">Add Products</h4>
            <p className="card-text">
              Click Here To Add Products To Your Menu List Or Edit Products From
              Your Menu List
            </p>
            <a
              href="#"
              className="btn btn-primary"
              onClick={() => {
                localStorage.getItem("key")
                  ? navigate("/add")
                  : alert("Please select the location..");
              }}
            >
              Update
            </a>
          </div>
        </div> */}
        <div
          className="card"
          style={{
            width: "18rem",
            height: "auto",
            marginTop: "1.5rem",
            backgroundColor: "#240a2b",
            color: "white",
          }}
        >

        </div>
      </div>
    </>
  );
};

export default AfterLogin;
