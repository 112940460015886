import React, { useEffect, useState } from "react";
import "./additems.css";
import axios from "axios";
import { MdDeleteForever,MdEdit, MdOutlineSaveAlt}from "react-icons/md";
import { GridLoader } from "react-spinners";



const Add_items = () => {
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [itemPrice, setItemPrice] = useState(1);
  const [loading,setLoading] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [expense, setExpense] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Fetch items when component mounts
    getItems();
  }, []);




  //pagination
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage + 1; // Adjust the starting index
  const currentItems = items.slice(indexOfFirstItem - 1, indexOfLastItem); // Adjust slice parameters
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log(expense);

  // -----------------------------------------------------------------------------
  const toggleEdit = (id) => {
    setUpdateId(id);
  };

  const saveItem = async (id) => {
    try {
      const updatedItem = items.find((item) => item.id === id);
      await axios.put(
        `${process.env.REACT_APP_API_URL}products/updateProducts/${id}`,
        updatedItem
      );
      setUpdateId(null);
    } catch (error) {
      console.error(error);
    }
  };
  // ------------------------------------------------------

  const getItems = async () => {
    setLoading(true);
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}products/getProducts`);
        console.log(response.data);
        setItems(response.data);
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
};


  const addItem = async () => {
   
    if (!itemName.length || !displayName.length || !itemPrice >= 1) {
      alert("enter valid value in each fields");
      return;
    }
    setLoading(true);
    const transformedItemName = itemName.replace(/\s+/g, '_');
    const transformedDisplayName = displayName.replace(/\s+/g, '_');
    try {
      const newItem = {
        item: transformedItemName,
        displayName:transformedDisplayName,
        price: itemPrice,
        expense
      };
      console.log(newItem);

      // Send POST request to add new item
      await axios.post(
          `${process.env.REACT_APP_API_URL}products/addProducts`,
        newItem
      );

      // Update items state to reflect the addition
      setItems([...items, newItem]);

      // Clear input fields
      setItemName("");
      setDisplayName("");
      setItemPrice(1);
    } catch (error) {
      console.error(error.message);
    }finally{
      setLoading(false);
    }
  };

  const deleteItem = async (id) => {
    try {
      // Send DELETE request to remove item by ID
      await axios.delete(
        `${process.env.REACT_APP_API_URL}products/deleteProducts/${id}`
      );

      // Update items state to reflect the deletion
      setItems(items.filter((item) => item.id !== id));
    } catch (error) {
      console.error(error.message);
    }
  };

 

  return(<>  {loading && (
    <div className="loader-overlay">
      <GridLoader size={50} color="black" />
    </div>
  )}
    <div className={`add-items ${loading ? 'blur' : ''}`}>
       <div className="container table-responsive py-5">
      <h3>Menu Items</h3>
        <table className="table table-bordered">
          <thead style={{ backgroundColor: "blue" }}>
            <tr >
            
              <th  style={{backgroundColor:"#516C9D" ,color:"white"}}scope="col">Item Name</th>
              <th  style={{backgroundColor:"#516C9D" ,color:"white"}}scope="col">Display Name</th>
              <th  style={{backgroundColor:"#516C9D" ,color:"white"}}scope="col">Pricing</th>
              <th  style={{backgroundColor:"#516C9D" ,color:"white"}}scope="col">Del/Edt</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id}>
             
                <td style={{ backgroundColor: "#C1C8D4" }}>
                  {updateId === item.id ? (
                    <input
                      type="text"
                      value={item.item}
                      className="form-control"
                      onChange={(e) =>
                        setItems((prevState) =>
                          prevState.map((prevItem) =>
                            prevItem.id === item.id
                              ? { ...prevItem, item: e.target.value }
                              : prevItem
                          )
                        )
                      }
                    />
                  ) : (
                    item.item
                  )}
                </td>
                <td style={{ backgroundColor: "#C1C8D4" }}>
                  {updateId === item.id ? (
                    <input
                      type="text"
                      value={item.displayName}
                      className="form-control"
                      onChange={(e) =>
                        setItems((prevState) =>
                          prevState.map((prevItem) =>
                            prevItem.id === item.id
                              ? { ...prevItem, displayName: e.target.value }
                              : prevItem
                          )
                        )
                      }
                    />
                  ) : (
                    item.displayName
                  )}
                </td>
                <td style={{ backgroundColor: "#C1C8D4" }}>
                  {updateId === item.id ? (
                    <input
                      type="number"
                      value={item.price}
                      className="form-control"
                      onChange={(e) =>
                        setItems((prevState) =>
                          prevState.map((prevItem) =>
                            prevItem.id === item.id
                              ? { ...prevItem, price: e.target.value }
                              : prevItem
                          )
                        )
                      }
                    />
                  ) : (
                    item.price
                  )}
                </td>
                <td>
                  {updateId === item.id ? (
                    <button className="btn btn-info" onClick={() => saveItem(item.id)}>
                      < MdOutlineSaveAlt />
                    </button>
                  ) : (
                    <button className="btn btn-dark" onClick={() => toggleEdit(item.id)}>
                      <MdEdit />
                    </button>
                  )}
                    <span> </span>
                    {updateId !== item.id && (
                  <button
                    onClick={() => deleteItem(item.id)}
                    className="btn btn-danger"
                  >
                    <MdDeleteForever />{" "}
                  </button>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <ul className="pagination">
          {Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (_, i) => (
            <li key={i} className="page-item">
              <button onClick={() => paginate(i + 1)} className="page-link">
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <form className="add-items-form" style={{ padding: "1.5rem" }}>
        <div className="form-group">
          <label htmlFor="itemName">Item Name</label>
          <input
            className="form-control"
            id="itemName"
            type="text"
            value={itemName}
            maxLength={20}
            onChange={(e) => {
              setItemName(e.target.value);
              setDisplayName(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="displayName">Display Name</label>
          <input
            className="form-control"
            id="displayName"
            type="text"
            value={displayName}
            maxLength={20}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="itemPrice">Item Price</label>
          <input
            className="form-control"
            id="itemPrice"
            type="number"
            value={itemPrice}
            onChange={(e) => {
              const newValue = Math.min(parseInt(e.target.value), 9999); // Ensure the value is not greater than 9999
              setItemPrice(newValue);
            }}
          />
        </div>
        <div
          className="form-check form-switch"
          style={{ padding: "1rem", marginTop: "1rem", marginLeft: "1.5rem" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onChange={() => setExpense(!expense)}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            click if it is an expense
          </label>
        </div>

         <button
                 type="submit"
                 style={{ margin: "1rem", padding: "1rem" }}
                 className="btn-get-started-main"
                 onClick={(e) => {
                   e.preventDefault();
                   addItem();
                 }}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            signing in...
                        </>
                    ) : (
                        "Add Items"
                    )}
                </button>
       
       
       
      </form>

     
     
    </div>
  </>)
};

export default Add_items;
