import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./upload.css";

const formatDate = (key, value) => {
    if (key === 'date') {
        // Check if value is a date
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
            // Format date as "YYYY-MM-DD"
            return date.toISOString().split('T')[0];
        }
    }
    return value;
};

const ConfirmationTable = () => {
    const location = useLocation();
    const response = location.state.response;
    const imgurl = location.state.imgurl;
    const navigate = useNavigate();
    

    return (
        <div style={{ overflowX: "auto",margin:".7rem",padding:".5rem" ,border:"1px solid black",borderRadius:"10px"}}>
            <h2>Response Table</h2>
            <img className="image-upload" src={imgurl} alt="image" />
            <table className="table table-bordered" >
                <tbody>
                    {Object.entries(response).map(([key, value]) => (
                        // Check if the value is not 0 and the key is not "_id"
                        value !== 0 && key !== "_id" && (
                            <tr key={key}>
                                <td style={{backgroundColor:"#516C9D" ,color:"white"}}>{key}</td>
                                <td>{formatDate(key, value)}</td>
                            </tr>
                        )
                    ))}
                </tbody>
            </table>
            <button type="button" className="btn btn-secondary" onClick={() => navigate('/upload')}>Go Back</button>
        </div>
    );
};

export default ConfirmationTable;
