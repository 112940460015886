// import React, { useRef, useState, useEffect } from "react";
// import axios from "axios";
// import "./upload.css";
// import { useNavigate } from "react-router-dom";
// import { GridLoader } from "react-spinners";

// const Upload = () => {
//   const [file, setFile] = useState(null);

//   const [loading, setLoading] = useState(false);
//   const [responseData, setResponseData] = useState({});
//   const [imgurl, setImgurl] = useState("");
//   const fileInputRef = useRef(null);
//   const [confirmToggle, setConfirmToggle] = useState(false);
//   const [checkedFields, setCheckedFields] = useState({});
//   const navigate = useNavigate();
//   const [userName,setUserName] = useState("");

//   const submitHandler = async (e) => {
//     e.preventDefault();

//     if (!file) {
//       alert("Please provide an image file");
//       return;
//     }
//     const userId = localStorage.getItem("key");

//     const fileUrl = URL.createObjectURL(file);

//     const formData = new FormData();
//     formData.append("file", file);

//     console.log(formData);
//     const url = `${process.env.REACT_APP_API_URL}sales/add`;
//     try {
//       setLoading(true);
//       const response = await axios.post(url,formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//         params: {
        
//           userId:userId
//       }
//       });

//       setResponseData((prevData) => response.data);
//       setImgurl(fileUrl);
//     } catch (error) {
//       alert("error sending file to server")
//       console.error("An error occurred while fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const addItems = (e) => {
//     e.preventDefault();
  
//     const userId = localStorage.getItem("key");
   
//     const objData = { ...responseData};
 
//     if (!isUpdateButtonEnabled()) {
//       alert("Please Check all the fields before updating . thankyou.")
//       return;
//     }
//     axios
//       .post(`${process.env.REACT_APP_API_URL}calculation/save`, objData,{
//         params: {
        
//             userId:userId
//         }
//     })
//       .then((res) => {
//         const response = res.data;
//         setConfirmToggle(!confirmToggle);
//         navigate("/confirm", { state: { response, imgurl } });
//       })
//       .catch((err) => {
//         console.error(err);
//         alert("error in sending data- ", err);
//       });
//   };

//   const handleChange = (key, value) => {
//     setResponseData((prevData) => ({
//       ...prevData,
//       [key]: value,
    
    
//     }));
//   };

//   useEffect(() => {
//     const initialCheckedFields = {};
//     Object.keys(responseData).forEach((key) => {
//       initialCheckedFields[key] = false;
//     });
//     setCheckedFields(initialCheckedFields);
//   }, [responseData]);

//   useEffect(() => {
//     // Update the checked status of all fields
//     const allFieldsChecked = Object.values(checkedFields).every((isChecked) => isChecked);
//     console.log("All fields checked:", allFieldsChecked);
//   }, [checkedFields]);

//   const handleCheckboxChange = (key) => {
//     setCheckedFields((prevCheckedFields) => ({
//       ...prevCheckedFields,
//       [key]: !prevCheckedFields[key],
//     }));
//   };

//   const isUpdateButtonEnabled = () => {
//     return Object.values(checkedFields).every((isChecked) => isChecked);
//   };

//   return (
//     <>
//       {loading && (
//         <div className="loader-overlay">
//           <GridLoader size={50} color="black" />
//         </div>
//       )}
//       <div className={`${loading ? 'blur' : ''}`}>
//         <div className="upload-first">
//           <span></span>
//           <form className="form">
//             <div className="main-text">
//               <h1 style={{ marginTop: ".5rem" }}> Check Your Expenses Now </h1>
//               <div>
           
//                 <div className="mb-3">
//                   <label htmlFor="formFile" className="form-label">
//                     Select Image
//                   </label>
//                   <input
//                     accept="image/*"
//                     ref={fileInputRef}
//                     onChange={(e) => setFile(e.target.files[0])}
//                     className="form-control"
//                     type="file"
//                     id="formFile"
//                   />
//                 </div>
//               </div>

//               <div style={{ paddingTop: "1rem" }}>
//                 <button
//                   className="btn-get-started-main"
//                   onClick={submitHandler}
//                   disabled={loading || !file}
//                 >
//                   {loading ? (
//                     <>
//                       <span
//                         className="spinner-border spinner-border-sm"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                       Loading...
//                     </>
//                   ) : (
//                     "Submit"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </form>

//           <div className="upload-right">
//             {imgurl && <div className="main-container">
//               <form style={{ maxWidth: "90%", padding: "1.5rem" }}>
//                 <img className="image-upload" src={imgurl} alt="image" />
//                 <div>
//                   <h2 style={{ padding: ".5rem" }}>
//                     Please Check the Details and confirm{" "}
//                   </h2>
//                 </div>

//                 {Object.keys(responseData).map((key) => (
//                   (
//                     <div key={key}>
//                       <div>
//                         <label className="form-label">
//                           {key}
//                         </label>
//                       </div>
//                       <div>
//                         <input
//                           style={{ width: "85%", height: "3rem", borderRadius: "5px", padding: ".5rem", marginRight: ".5rem" }}
//                           type="text"
//                           value={responseData[key]}
//                           onChange={(e) => handleChange(key, e.target.value)}
//                         />
//                         <span> <input
//                           style={{ accentColor: "#03C03C", zoom: 1.5 }}
//                           type="checkbox"
//                           checked={checkedFields[key]}
//                           onChange={() => handleCheckboxChange(key)}
//                         /></span>
//                       </div>
//                     </div>
//                   )
//                 ))}
              
//                 <button
//                   type="submit"
//                   style={{ margin: "1rem", padding: "1rem" }}
//                   onClick={addItems}
//                   className="btn-get-started-main"
//                   // disabled={!isUpdateButtonEnabled()}
//                 >
//                   Upload Data
//                 </button>
//               </form>
//             </div>}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Upload;


import React, { useState, useRef } from "react";
import { GridLoader } from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Button, ListGroup } from "react-bootstrap";

const Upload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [imgurl, setImgurl] = useState("");
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please provide an image file");
      return;
    }
    const userId = localStorage.getItem("key");

    const fileUrl = URL.createObjectURL(file);

    const formData = new FormData();
    formData.append("file", file);

    console.log(formData);
    const url = `${process.env.REACT_APP_API_URL}sales/add`;
    try {
      setLoading(true);
      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          userId: userId
        }
      });

      setResponseData(response.data);
      setImgurl(fileUrl);
    } catch (error) {
      alert("error sending file to server")
      console.error("An error occurred while fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (key, value) => {
    setResponseData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleConfirm = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitData = () => {
    const userId = localStorage.getItem("key");
    const objData = { ...responseData };

    axios
      .post(`${process.env.REACT_APP_API_URL}calculation/save`, objData, {
        params: {
          userId: userId
        }
      })
      .then((res) => {
        const response = res.data;
        navigate("/confirm", { state: { response, imgurl } });
      })
      .catch((err) => {
        console.error(err);
        alert("error in sending data- ", err);
      });

    setShowModal(false);
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <GridLoader size={50} color="black" />
        </div>
      )}
      <div className={`${loading ? 'blur' : ''}`}>
        <div className="upload-first">
          <span></span>
          <form className="form">
            <div className="main-text">
              <h1 style={{ marginTop: ".5rem" }}> Check Your Expenses Now </h1>
              <div>
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Select Image
                  </label>
                  <input
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={(e) => setFile(e.target.files[0])}
                    className="form-control"
                    type="file"
                    id="formFile"
                  />
                </div>
              </div>

              <div style={{ paddingTop: "1rem" }}>
                <button
                  className="btn-get-started-main"
                  onClick={submitHandler}
                  disabled={loading || !file}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </div>
          </form>

          <div className="upload-right">
            {imgurl && <div className="main-container">
              <form style={{ maxWidth: "90%", padding: "1.5rem" }}>
                <img className="image-upload" src={imgurl} alt="image" />
                <div>
                  <h2 style={{ padding: ".5rem" }}>
                    Please Check the Details and confirm{" "}
                  </h2>
                </div>

                {Object.keys(responseData).map((key) => (
                  <div key={key}>
                    <div>
                      <label className="form-label">
                        {key}
                      </label>
                    </div>
                    <div>
                      <input
                        style={{ width: "85%", height: "3rem", borderRadius: "5px", padding: ".5rem", marginRight: ".5rem" }}
                        type="text"
                        value={responseData[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              
                <button
                  type="button"
                  style={{ margin: "1rem", padding: "1rem" }}
                  onClick={handleConfirm}
                  className="btn-get-started-main"
                >
                  Confirm Data
                </button>
              </form>
            </div>}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {Object.entries(responseData).map(([key, value]) => (
              <ListGroup.Item key={key}>
                <strong>{key}:</strong> {value}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmitData}>
            Confirm and Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Upload;