import React, { useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    const [userName, setUserName] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const loginHandler = async (e) => {
        e.preventDefault();
       // Start loading
        // if(userName.length<=0 || pass.length<=0){
        //     alert("please fill the password and username..")
        //     return;
        // }
        setLoading(true); 
        const cred = {
            username: userName,
            password: pass
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}api/users/login`, cred);
            const data = response.data;
            console.log(data);
            if(data === "Invalid credentials"){
                alert("Please use valid credentials .");
                return ;
            }
         
            navigate('/afterlogin');
            localStorage.setItem("key",data.id);
            localStorage.setItem("username",data.username)
            console.log(data);
        } catch (error) {
            console.error(error);

            alert("Please use valid credentials");
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className='login-main'>
            <form>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">User Name</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" value={userName} onChange={(e) => setUserName(e.target.value)} aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input type="password" className="form-control" value={pass} onChange={(e) => setPass(e.target.value)} id="exampleInputPassword1" />
                </div>

                <button
                    className="btn-get-started-main"
                    onClick={loginHandler}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Signing in...
                        </>
                    ) : (
                        "Submit"
                    )}
                </button>
            </form>
        </div>
    );
};

export default Login;
